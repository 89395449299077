<template>
  <v-container class="service-provider-profile-container">
    <v-row>
      <confirm-dialog
        :confirm-click="removeServiceProviderFromCompany"
        :show="dialog"
        @close="closeConfirmationDialog"
      >
        <v-row class="confirm-container">
          <div class="confirm-title">
            Are you sure you want to remove {{ providerName }}?
          </div>
          <div class="confirm-sub-title">
            All offers of the Service Provider will be removed
            and all the open requests will be cancelled.
          </div>
        </v-row>
      </confirm-dialog>
      <v-col
        cols="12"
        lg="7"
        md="8"
        offset-md="1"
        sm="8"
        xl="5"
      >
        <v-card>
          <v-card-text>
            <v-container>
              <v-text-field
                ref="providerName"
                v-model="providerName"
                :disabled="true"
                :rules="[rules.required]"
                class="mt-6"
                label="Name"
                placeholder="Enter the provider name"
                required
              />
              <v-text-field
                v-model="emailAddress"
                :disabled="true"
                label="Email Address"
              />
              <v-text-field
                ref="address"
                v-model="address"
                :disabled="true"
                :rules="[rules.required]"
                label="Address"
                placeholder="Enter the provider address"
                required
              />
              <v-text-field
                ref="tagline"
                v-model="tagline"
                :disabled="true"
                :rules="[rules.required]"
                label="Tagline"
                placeholder="Enter a tagline"
                required
              />
              <div class="about-container">
                <label>About</label>
                <p
                  class="ql-editor"
                  v-html="$sanitize(about)"
                />
              </div>
              <label>Contact Info</label>
              <div
                v-for="(contact, index) in contactInfo"
                :key="index"
              >
                <app-contact-info
                  :contact="contact"
                  :read-only="true"
                  :select-options="contactInfoValues"
                  @removeContact="removeContactInfo($event)"
                />
              </div>
              <div
                class="video_container mt-2 mb-4"
              >
                <video-display
                  :edit-mode="true"
                  :storage-key="storageKey"
                  :url="companyVideo"
                />
              </div>
              <app-button
                secondary
                @click="openConfirmationDialog"
              >
                Remove from company
              </app-button>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { ConfirmDialog, ContactInfo, ValidateMixin } from '@kickbox/common-admin';
import { contactInfoValues } from '@kickbox/common-util';
import { VideoDisplay } from '@kickbox/common-components';
import serviceProviderService from '@/services/serviceProviderService';

export default {
  components: {
    appContactInfo: ContactInfo,
    ConfirmDialog,
    VideoDisplay
  },
  mixins: [ValidateMixin],
  props: ['id'],
  data() {
    return {
      fromExternal: false,
      contactInfoValues,
      providerName: '',
      emailAddress: '',
      address: '',
      tagline: '',
      about: '',
      contactInfo: '',
      companyVideo: '',
      dialog: false
    };
  },
  computed: {
    ...mapGetters([
      'serviceProviderById',
      'company'
    ]),
    provider() {
      return this.serviceProviderById(this.id);
    },
    formMandatoryFields() {
      return {
        providerName: this.providerName,
        address: this.address,
        tagline: this.tagline,
        about: this.about
      };
    },
    storageKey() {
      return `videos/provider-${this.id}-companyVideo`;
    }
  },
  created() {
    if (this.provider) {
      if (this.provider.logo) {
        this.initialImage = this.provider.logo;
      }
      this.providerName = this.provider.name;
      this.emailAddress = this.provider.email;
      this.address = this.provider.address;
      this.tagline = this.provider.tagline;
      this.about = this.provider.about;
      this.contactInfo = this.provider.contactInfo;
      this.companyVideo = this.provider.companyVideo;
    } else {
      this.$router.push({ name: 'AdminHome' });
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromExternal = from.fullPath === '/';
    });
  },
  methods: {
    ...mapMutations([
      'updateServiceProvider'
    ]),
    openConfirmationDialog() {
      this.dialog = true;
    },
    closeConfirmationDialog() {
      this.dialog = false;
    },
    addContactInfo() {
      this.contactInfo.push({ type: '', value: '' });
    },
    removeContactInfo(contact) {
      this.contactInfo = this.contactInfo
        .filter((item) => !(item.type === contact.type && item.value === contact.value));
    },
    removeServiceProviderFromCompany() {
      serviceProviderService.removeServiceProviderFromCompany(this.provider.id)
        .then(() => {
          this.closeConfirmationDialog();
          if (this.fromExternal) {
            this.$router.push({ name: 'AdminManageServiceProviders' });
          } else {
            this.$router.go(-1);
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~quill/dist/quill.core.css';
  label{
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
  }

  .add-link {
    font-size: 12px;
    cursor: pointer;
  }

  .croppa-container {
    border: 1px solid grey;
  }
  .confirm-container {
    .confirm-title {
      font-size: 18px;
      margin-bottom: 1rem;
    }
    .confirm-sub-title {
      font-size: 15px;
      font-weight: normal
    }
  }
  .about-container {
    color: #808080;
    p {
      padding-left: 0;
    }
    .ql-editor {
      font-size: 15px;
    }
  }
</style>
